import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  setDoc,
  arrayUnion,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";

// Function to get the current timestamp in both Unix and human-readable EST format
const getTimestampData = () => {
  const now = new Date();
  const timestamp = Math.floor(now.getTime() / 1000); // Unix Epoch Timestamp

  const options = {
    timeZone: "America/New_York",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const readableTimestamp = new Intl.DateTimeFormat("en-US", options).format(
    now
  );

  return { timestamp, readableTimestamp };
};

// Function to get a document reference based on email
const getUserAnalyticsRef = (email) => {
  const docId = email.replace(/[^a-zA-Z0-9]/g, "_"); // Normalize email to use as Firestore doc ID
  return doc(db, "signupAnalytics", docId);
};

// Function to update or create a document for trial page entry tracking
export const updateTrialPageEntry = async (email) => {
  if (!email) return;

  try {
    const userRef = getUserAnalyticsRef(email);
    const userDoc = await getDoc(userRef);
    const { timestamp, readableTimestamp } = getTimestampData();
    const trialEntry = { timestamp, readableTimestamp };

    if (userDoc.exists()) {
      // Update existing document
      await updateDoc(userRef, {
        trialPageEntry: arrayUnion(trialEntry),
      });
    } else {
      // Create a new document using a fixed ID
      await setDoc(userRef, {
        email,
        trialPageEntry: [trialEntry],
      });
    }

    console.log(`Trial page entry updated for: ${email}`);
  } catch (error) {
    console.error("Error updating trial page entry:", error);
  }
};

// Function to update or create a document for Stripe button click tracking
export const updateStripeButtonClicked = async (email) => {
  if (!email) return;

  try {
    const userRef = getUserAnalyticsRef(email);
    const userDoc = await getDoc(userRef);
    const { timestamp, readableTimestamp } = getTimestampData();
    const stripeEntry = { timestamp, readableTimestamp };

    if (userDoc.exists()) {
      // Update existing document
      await updateDoc(userRef, {
        stripeButtonClicked: arrayUnion(stripeEntry),
      });
    } else {
      // Create a new document using a fixed ID
      await setDoc(userRef, {
        email,
        stripeButtonClicked: [stripeEntry],
      });
    }

    console.log(`Stripe button click recorded for: ${email}`);
  } catch (error) {
    console.error("Error updating Stripe button click:", error);
  }
};
