import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import {
  updateStripeButtonClicked,
  updateTrialPageEntry,
} from "../networking/AnalyticsCalls";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import quantumLogo from "../quantum_logo.png"; // Import the Quantum logo

const Signup = ({ trialEmail }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup } = useAuth();
  const navigate = useNavigate();

  if (trialEmail != null) {
    updateTrialPageEntry(trialEmail);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password);
      navigate("/");
    } catch (error) {
      console.error("Failed to sign up", error);
    }
  };

  const handleSignIn = () => {
    navigate("/login"); // Navigate to the login page
  };

  return (
    <Container component="main" maxWidth="xs">
      {trialEmail ? (
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 8,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Quantum Logo */}
          <Box sx={{ mb: 2 }}>
            <img src={quantumLogo} alt="Quantum Logo" style={{ width: 150 }} />
          </Box>

          {/* Trial Text */}
          <Typography variant="h6" color="#00274D" sx={{ fontWeight: "bold" }}>
            Quantum Solicitation Dashboard
          </Typography>
          <Typography variant="subtitle1" color="#00274D" sx={{ mt: 1 }}>
            30-Day Free Trial
          </Typography>
          <Typography variant="body2" sx={{ mt: 1, color: "gray", px: 2 }}>
            Get unlimited access to new opportunities sourced just for you.
          </Typography>

          {/* Start Trial Button */}
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              bgcolor: "#00274D",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#001a33",
              },
            }}
            onClick={() => {
              updateStripeButtonClicked(trialEmail);
              const encodedEmail = encodeURIComponent(trialEmail);
              const successUrl = encodeURIComponent(
                `https://app.quantum-crm.com/?status=success&subemail=${encodedEmail}`
              ); // Change to production URL when deploying

              window.location.href = `https://buy.stripe.com/aEUdS3ec465geBibIJ?prefilled_email=${encodedEmail}&success_url=${successUrl}`;
            }}
          >
            Start Your Free Trial
          </Button>
        </Paper>
      ) : (
        <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#00274D" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" color="#00274D">
              Sign Up
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ style: { color: "#00274D" } }}
                InputProps={{
                  style: { color: "#00274D" },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ style: { color: "#00274D" } }}
                InputProps={{
                  style: { color: "#00274D" },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "#00274D", color: "#fff" }}
              >
                Sign Up
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleSignIn}
                sx={{
                  color: "#00274D",
                  borderColor: "#00274D",
                  "&:hover": {
                    backgroundColor: "#00274D",
                    color: "#fff",
                  },
                }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default Signup;
