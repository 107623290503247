import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Search from "./pages/Search";
import Detail from "./pages/Detail";
import CompleteAccount from "./pages/CompleteAccount";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { RefreshProvider } from "./context/RefreshContext";
import PrivateRoute from "./components/PrivateRoute";
import theme from "./theme";

// Utility to extract query parameters from the URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <RefreshProvider>
          <Router>
            <Header />
            <Routes>
              {/* Handle redirection based on authentication and URL params */}
              <Route path="/" element={<AuthRedirect />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignupWithTrialEmail />} />
              <Route
                path="/complete-account"
                element={<CompleteAccountWithSubEmail />}
              />

              {/* Private routes */}
              <Route
                path="/search"
                element={
                  <PrivateRoute>
                    <Search />
                  </PrivateRoute>
                }
              />
              <Route
                path="/detail/:id"
                element={
                  <PrivateRoute>
                    <Detail />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Router>
        </RefreshProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

// Component to handle authentication and redirection logic
function AuthRedirect() {
  const { currentUser } = useAuth();
  const query = useQuery();
  const emailParam = query.get("email") || query.get("userEmail");
  const status = query.get("status");
  const subEmail = query.get("subemail");

  useEffect(() => {
    // Remove query parameters from the URL after extracting them
    if (emailParam || query.get("noticeid") || status || subEmail) {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [emailParam, status, subEmail]);

  // Redirect to CompleteAccount if both status and subEmail exist
  if (status && subEmail) {
    return (
      <Navigate
        to={`/complete-account?subEmail=${encodeURIComponent(subEmail)}`}
        replace
      />
    );
  }

  if (currentUser) {
    return <DashboardWrapper />;
  }

  return emailParam ? (
    <Navigate to={`/signup?email=${emailParam}`} replace />
  ) : (
    <Navigate to="/login" replace />
  );
}

// Wrapper component to pass noticeid to Dashboard and remove it from URL
function DashboardWrapper() {
  const query = useQuery();
  const noticeid = query.get("noticeid");

  useEffect(() => {
    if (noticeid) {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [noticeid]);

  return <Dashboard noticeid={noticeid} />;
}

// Wrapper for Signup that extracts trialEmail from the URL and removes it
function SignupWithTrialEmail() {
  const query = useQuery();
  const trialEmail = query.get("email") || query.get("userEmail");

  useEffect(() => {
    if (trialEmail) {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [trialEmail]);

  return <Signup trialEmail={trialEmail} />;
}

// Wrapper for CompleteAccount that extracts subEmail from the URL and removes it
function CompleteAccountWithSubEmail() {
  const query = useQuery();
  const subEmail = query.get("subEmail");

  useEffect(() => {
    if (subEmail) {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [subEmail]);

  return <CompleteAccount subEmail={subEmail} />;
}

export default App;
