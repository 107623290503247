import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  queryTempStripeUser,
  updateUserAccount,
  createUserDocument,
} from "../networking/AccountCalls";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Avatar,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const CompleteAccount = ({ subEmail }) => {
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState(subEmail || ""); // Set subEmail if available
  const [password, setPassword] = useState("");
  const { signup, login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (subEmail) {
      setEmail(subEmail); // Ensure email field is set if subEmail exists
    }
  }, [subEmail]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();

    try {
      let isSignUp = false;

      try {
        await login(email, password);
      } catch (loginError) {
        console.log("Login failed, attempting signup...");

        try {
          await signup(email, password);
          isSignUp = true;
        } catch (signupError) {
          console.error("Signup failed:", signupError);
          alert("Signup failed. Please check your details and try again.");
          return;
        }
      }

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const userEmail = user.email;

          if (userEmail.includes("@raymondllc.com") && isSignUp) {
            const raymondUserData = {
              priceId: "raymond",
              stripeCustomerId: "raymond",
              subscriptionStatus: "raymond",
              subscriptionId: "raymond",
              subscriptionCreated: "raymond",
              subscriptionProduct: "raymond",
              subscriptionCurrentPeriodEnd: "raymond",
            };
            const success = await createUserDocument(
              uid,
              email,
              company,
              raymondUserData
            );
            if (success) {
              localStorage.setItem(`newCompletedAccount`, "true");
              navigate("/search");
            } else {
              console.error("Failed to create user document.");
            }
          } else {
            const tempStripeUserData = await queryTempStripeUser(email);

            if (!tempStripeUserData) {
              alert(
                "No subscription data found. Please sign up for a subscription."
              );
              return;
            }

            if (!isSignUp) {
              console.log("logged in");
              const userUpdated = await updateUserAccount(
                email,
                tempStripeUserData
              );
              if (userUpdated) {
                localStorage.setItem(`newCompletedAccount`, "true");
                navigate("/search");
                return;
              }
            }

            if (isSignUp) {
              const success = await createUserDocument(
                uid,
                email,
                company,
                tempStripeUserData
              );
              if (success) {
                localStorage.setItem(`newCompletedAccount`, "true");
                navigate("/search");
              } else {
                console.error("Failed to create user document.");
              }
            }
          }
        } else {
          console.error("Failed to retrieve user ID.");
        }
      });
    } catch (error) {
      console.error("Error during account setup process", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#00274D" }}>
            <CheckCircleOutlineIcon />
          </Avatar>
          <Typography component="h1" variant="h5" color="#00274D">
            Complete Your Account Setup
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="company"
              label="Company"
              name="company"
              autoComplete="company"
              autoFocus
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              InputLabelProps={{ style: { color: "#00274D" } }}
              InputProps={{
                style: { color: "#00274D" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              disabled={!!subEmail} // Disable input if subEmail exists
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: "#00274D" } }}
              InputProps={{
                style: { color: "#00274D" },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: "#00274D" } }}
              InputProps={{
                style: { color: "#00274D" },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "#00274D", color: "#fff" }}
            >
              Finish Account Setup
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default CompleteAccount;
